import React, { Component } from "react";
import { ClipLoader } from "react-spinners";
import PublicHeaderUS from "../components/PublicHeaderUS/PublicHeaderUS";
import EyeIcon from "../assets/images/icons/open.png";
import HiddenEyeIcon from "../assets/images/icons/hidden.png"
import { history } from "../_helpers";
import i18n from "i18next";
import validator from 'validator';
import { isAndroid, isIOS } from "react-device-detector";
import ReactInputMask from "react-input-mask";
import 'antd/dist/antd.css';
import i18next from 'i18next';
import { Modal, Button} from 'antd';
import { v4 as uuidv4 } from "uuid";
import { config } from "../_config/index";
import SocialSignUpUS from "./SocialSignUpUS";
const modal = Modal;
class NewSignUp extends Component {
  constructor(props) {
    document.title = `Coke & Go US | Signup`;
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      firstName: urlParams.get('Name') || "",
      isSocialMedia: urlParams.get('isSocialMedia') || "",
      socialType: urlParams.get('socialType') || "",
      lastName: urlParams.get('lastName') || "",
      authId: urlParams.get('authId') || "",
      requireToVerifyEmail: urlParams.get('requireToVerifyEmail') || "",
      PrimaryEmail: urlParams.get('Email') || "",
      zipcode: "",
      birthDate: "",
      isLoading: false,
      openeye: false,
      inputType: false,
      openeye1: false,
      inputType1: false,
      password: '',
      confirmpassword: '',
      isInvalidPassword: false,
      isPasswordMatched: false,
      emailConsent: false,
      showPopup: false,
      notificationsConsent: false,
      currentStep: 1,
      errors: {
        birthDate: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.toggleImage = this.toggleImage.bind(this);
    this.getImageName = this.getImageName.bind(this);
    this.toggleImage1 = this.toggleImage1.bind(this);
    this.getImageName1 = this.getImageName1.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (name === 'zipcode') {
      const numericValue = value.replace(/\D/g, '');
      this.setState({ [name]: numericValue });
    } else {
      this.setState({ [name]: value });
    }
  }


  handleDobChange(event) {
    const { value } = event.target;
    let errors = this.state.errors;
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    if (!datePattern.test(value)) {
        errors.birthDate = 'Date of Birth must be in MM/DD/YYYY format!';
    } else {
        const [month, day, year] = value.split('/').map(Number);
        const birthDate = new Date(year, month - 1, day);
        const today = new Date();

        if (birthDate > today) {
            errors.birthDate = 'Date of Birth cannot be a future date!';
        } else {
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            const dayDiff = today.getDate() - birthDate.getDate();

            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }

            if (age < 14 || age > 120) {
                errors.birthDate = `Oops! Not quite 14 years old yet. We'll be thrilled to have you once you are.`;
            } else {
                const isValidDate = birthDate.getFullYear() === year &&
                                    birthDate.getMonth() === month - 1 &&
                                    birthDate.getDate() === day;

                if (!isValidDate) {
                    errors.birthDate = 'Invalid date for the specified month!';
                } else {
                    errors.birthDate = '';
                }
            }
        }
    }

    this.setState({ errors, birthDate: value });
}

  convertDateForApi(date) {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`; 
  }

  toggleImage() {
    this.setState({
      openeye: !this.state.openeye,
      inputType: !this.state.inputType,
    });
  }
  getImageName() {
    return this.state.openeye ? "showeye" : "hideeye";
  }

  toggleImage1() {
    this.setState({
      openeye1: !this.state.openeye1,
      inputType1: !this.state.inputType1,
    });
  }
  getImageName1() {
    return this.state.openeye1 ? "showeye" : "hideeye";
  }

  validatePassword(value) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<_-\s])[A-Za-z\d!@#$%^&*()<_-\s]{8,}$/;
    const isValid = passwordRegex.test(value);
    this.setState({
      isInvalidPassword: !isValid
    });
  }

  validateConfirmPassword(value) {
    const { password } = this.state;
    const isMatched = password === value;
    this.setState({
      isPasswordMatched: !isMatched
    });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    }, () => {
      if (name === 'password') {
        this.validatePassword(value);
        if (this.state.confirmpassword !== '') {
          this.validateConfirmPassword(this.state.confirmpassword);
        }
      } else if (name === 'confirmpassword') {
        this.validateConfirmPassword(value);
      }
    });
  }

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  handleBack = () => {
    if (this.state.isSocialMedia == 'true') {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 2,
      }));
    }
    else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
      }));
    }
  };

  handleContinue() {
    if (this.state.isSocialMedia == 'true') {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 2,
      }));
    }
    else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
      }));
    }
  }
  registerWithSocialMedia = (event) => {
    event.preventDefault();
    this.setState({
      isLoading:true
    })
    const applicationTypeId=8;
    const {PrimaryEmail:primaryEmail,birthDate,firstName,lastName,authId,socialType:socialMediaType,emailConsent:mailMarketing, notificationsConsent:smsMarketing,isSocialMedia} = this.state;
    const isManualEmail=false
    const isQuickSignUp = true
    let sessionUId = sessionStorage.getItem("sessionId");
    if(!sessionUId){
        sessionUId = uuidv4();
        sessionStorage.setItem('sessionId',sessionUId);
    }
    // adding platform for report
    const platform = "web";
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        primaryEmail,
        birthDate: this.convertDateForApi(birthDate),
        firstName,
        lastName,
        authId,
        socialMediaType,
        applicationTypeId,
        mailMarketing,
        smsMarketing,
        isSocialMedia,
        isManualEmail,
        sessionUId,
        platform,
        isQuickSignUp

      })
    };

    fetch(`${config.envUrl}VendingAppApi/userRegistrationSocialMedia`, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .catch(err => {
        console.log(err)
        this.setState({
          isLoading:false,
          showPopup: true,
          popupmsg: i18n.t("CommunicationError"),
        })
      })
      .then(data => {
        let msg = '';
        if (data.success && data.code == 5002) {
          if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS";
          } else if (isIOS) {
            window.location.href = "https://apps.apple.com/in/app/coke-go/id1594145032";
          } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS";
          }
        }
        else {
          switch (data.code) {
            case 155252:
              msg = i18n.t("RegisterWithin24HoursPostDelete");
              break;
            case 155253:
              msg = i18n.t("SuspendAccountMulitpleRegistration");
              break;
            default:
              msg = i18n.t("CommunicationError");
          }
          this.setState({
            showPopup: true,
            popupmsg: msg,
          });
        }
        this.setState({ isLoading: false });
      })
  }

  handleSubmit(event) {
    event.preventDefault();
    const { firstName, PrimaryEmail, zipcode, birthDate, errors, password, confirmpassword, emailConsent, notificationsConsent, isSocialMedia, } = this.state;
    
    if (errors.birthDate) {
      return;
    }

    const trimmedPassword = password.trim();
    const trimmedConfirmPassword = confirmpassword.trim();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<_-\s])[A-Za-z\d!@#$%^&*()<_-\s]{8,}$/;
    const isValidPassword = passwordRegex.test(trimmedPassword);
    const isPasswordMatched = trimmedPassword === trimmedConfirmPassword;


    this.setState({
      isInvalidPassword: !isValidPassword,
      isPasswordMatched: !isPasswordMatched
    });


    let sessionUId = sessionStorage.getItem("sessionId");
    if (!sessionUId) {
      sessionUId = uuidv4();
      sessionStorage.setItem('sessionId', sessionUId);
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        PrimaryEmail,
        firstName,
        lastName: '',
        mobileNumber: '',
        zipcode,
        birthDate: this.convertDateForApi(birthDate),
        password,
        confirmpassword,
        applicationTypeId: 8,
        userTypeId: 1,
        mailMarketing: emailConsent,
        smsMarketing: notificationsConsent,
        platform: "web",
        isQuickSignUp: true,
        sessionUId
      }).toString()
    };
    this.setState({ isLoading: true });

    fetch(`${config.envUrl}VendingAppApi/userRegistrationV2`, requestOptions)
      .then(response => response.json())
      .then(data => {
        let msg = '';
        if (data.success && data.code === 5002) {
          if (isAndroid) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS";
          } else if (isIOS) {
            window.location.href = "https://apps.apple.com/in/app/coke-go/id1594145032";
          } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.ebest.vending.cocaColaUS";
          }
        }
        else {
          switch (data.code) {
            case 155250:
              msg = i18n.t("Flood_Registration");
              break;
            case 15487:
              msg = i18n.t("Email Id already registered.");
              break;
            case 1523:
              msg = i18n.t("Email has not send successful.");
              break;
            case 503:
              msg = i18n.t("Email Id already registered.");
              break;
            case 422:
              msg = i18n.t("Missing Parameters");
              break;
            case 155252:
              msg = i18n.t("RegisterWithin24HoursPostDelete");
              break;
            case 155253:
              msg = i18n.t("SuspendAccountMulitpleRegistration");
              break;
            default:
              msg = i18n.t("CommunicationError");
          }
          this.setState({
            showPopup: true,
            popupmsg: msg,
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.error('Error:', error);
        this.setState({
          isLoading:false,
          showPopup: true,
          popupmsg: i18n.t("CommunicationError"),
        })
      });
  }

  SocialSignIn = (user) => {
    if(user.Email==undefined||user.Email==''){
      user.Email=this.state.PrimaryEmail
    }
    let url = new URL(window.location.href)
    url.search = new URLSearchParams(user);
    window.location.href = url
  }
  render() {
    const { errors, PrimaryEmail, firstName, zipcode, birthDate, emailConsent, notificationsConsent, isInvalidPassword, isPasswordMatched, password, confirmpassword, currentStep, showPopup, popupmsg, isLoading } = this.state;
    const isFormValid = firstName.trim().length > 0 && PrimaryEmail.trim().length > 0 && zipcode.length === 5 && birthDate && !errors.birthDate;
    const style = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };
    const imagesPath = {
      showeye: EyeIcon,
      hideeye: HiddenEyeIcon,
    };
    const isDisabled = !password || !confirmpassword || isInvalidPassword || isPasswordMatched;

    return (
      <>
        {currentStep === 1 && (
          <div className="signup-area">
            <div className="container">
              <PublicHeaderUS
                Title={i18n.t('Sign Up')}
                backLink="/hurryform"
                subHeading={i18n.t('Tell us about yourself.')}
                Desc={i18n.t('We’ll use this to personalize the experience for you.')}
                Required={i18n.t('Required')}
              />
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center p-0">
                  <div className="col-12 p-0">
                    <form onSubmit={this.state.isSocialMedia=='' ? this.handleSubmit : this.registerWithSocialMedia}>
                      <div className="form-groupus d-flex" style={{ borderBottom: '1px solid #000000', outline: 'none', marginBottom: '10px' }}>
                        <label style={{ fontSize: '14px', fontWeight: '600', paddingLeft: '4px' }}>{i18n.t("FirstName")}:</label>&nbsp;
                        <p className="m-0" style={{ fontSize: '14px', fontWeight: '600' }}>{this.state.firstName}</p>
                      </div>
                      <div className="form-groupus d-flex" style={{ borderBottom: '1px solid #000000', outline: 'none', marginBottom: '10px' }}>
                        <label style={{ fontSize: '14px', fontWeight: '600', paddingLeft: '4px' }}>{i18n.t("Email")}:</label>&nbsp;
                        <p className="m-0" style={{ fontSize: '14px', fontWeight: '600' }}>{this.state.PrimaryEmail}</p>
                      </div>

                      <div className="row">
                        <div className="form-groupus ziparea">
                          <label className="mb-0 text-black" style={{ fontWeight: '600', fontSize: '14px' }}>{i18n.t("Zip Code") + "*"}</label>
                          <input
                            id="zip"
                            type='text'
                            className="zipplace shadow-none border-radius-none pl-0"
                            placeholder={i18n.t("Enter Zip Code")}
                            name="zipcode"
                            value={this.state.zipcode}
                            onChange={this.handleChange}
                            aria-label="Zip Code"
                            aria-describedby="basic-addon1"
                            maxLength="5"
                          />
                        </div>
                        <div className="form-groupus datearea">
                          <label className="m-0" style={{ fontWeight: '600', fontSize: '14px' }}>{i18n.t("DoB") + "*"}</label>
                          <ReactInputMask
                            name="birthDate"
                            onChange={this.handleDobChange}
                            className="dateplace shadow-none border-radius-none p-0"
                            mask="99/99/9999"
                            maskChar="_"
                            value={this.state.birthDate}
                            placeholder={'MM/DD/YYYY'}
                          />
                          {errors.birthDate &&
                            <label className="text-danger" style={{ fontWeight: '600', fontSize: '13px' }}>{errors.birthDate}</label>}
                        </div>
                      </div>
                      <button className="btn signup-button w-100 text-white" disabled={!isFormValid} onClick={this.handleContinue}>Continue</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
           {this.state.isSocialMedia=='' && 
            <SocialSignUpUS SocialSignIn={this.SocialSignIn} />
           }

          </div>)}
        {currentStep === 2 && (
          <div className='password-area'>
            <div className='container'>
              <PublicHeaderUS Title={i18n.t('ConfirmPassword')} backStep={this.handleBack} subHeading={i18n.t('PasswordHeading')} />
              <div className="row justify-content-center p-0">
                <div className="p-0 col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="col-12 p-0">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-groupus">
                        <div className="position-relative mt-1">
                          <label className="mb-0 text-black" style={{ fontWeight: '600', fontSize: '14px' }}>{i18n.t("Password") + "*"}</label>
                          <input
                            id="password"
                            type={this.state.inputType ? "text" : "password"}
                            className="password-hold shadow-none border-radius-none pl-0"
                            placeholder={i18n.t("Enter Password")}
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            autoComplete="new-password"
                          />
                          <span>
                            <img
                              src={imagesPath[this.getImageName()]}
                              alt="eye"
                              onClick={this.toggleImage}
                              id="eye"
                              height="27px"
                              className="seenpassword"
                            />
                          </span>
                        </div>
                        {
                          isInvalidPassword &&
                          <label className="text-danger" style={{ margin: 0, fontWeight: '600', fontSize: '12px' }}>{i18n.t("The password must have at least one uppercase, one lowercase, one numerical digit, one special character (!@#$%^&*(<_-]) and a minimum of eight characters.")}</label>
                        }
                      </div>
                      <div className="form-groupus">
                        <div className="position-relative">
                          <label className="mb-0" style={{ fontWeight: '600', fontSize: '14px' }}>{i18n.t("ConfirmPassword") + "*"}</label>
                          <input
                            id="confirmpassword"
                            type={this.state.inputType1 ? "text" : "password"}
                            className="password-hold shadow-none border-radius-none pl-0"
                            placeholder={i18n.t("ConfirmPassword")}
                            name="confirmpassword"
                            value={this.state.confirmpassword}
                            onChange={this.handleInputChange}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            autoComplete="new-password"
                          />
                          <span>
                            <img
                              src={imagesPath[this.getImageName1()]}
                              alt="eye"
                              onClick={this.toggleImage1}
                              id="eye1"
                              height="27px"
                              className="seenpassword"
                            />
                          </span>
                        </div>
                        {
                          isPasswordMatched &&
                          <label className="text-danger" style={{ fontWeight: '600', fontSize: '12px' }}>{i18n.t("Password and confirm password does not match")}</label>
                        }
                      </div>
                      <button className="btn confirm-button w-100 mt-4 text-white" disabled={isDisabled} onClick={this.handleContinue}>Continue</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        {currentStep === 3 && (
          <div className='password-area'>
            <div className='container'>
              <PublicHeaderUS Title={i18next.t('Consent')} backStep={this.handleBack} subHeading={i18next.t('ConsentHeading')} />
              <div className="row justify-content-center p-0">
                <div className="p-0 col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="col-12 p-0">
                    <form onSubmit={this.state.isSocialMedia=='' ? this.handleSubmit : this.registerWithSocialMedia}>
                      <div className="consent-check">
                        <input
                          id="emailterms"
                          type="checkbox"
                          className="policyus-checkbox"
                          name="emailConsent"
                          checked={emailConsent}
                          onChange={this.handleCheckboxChange}
                        />
                        <label className="consent-check-label" htmlFor="emailterms">
                          <p>Be the first to know:</p> I consent to receive personalized promotional emails from The Coca-Cola Company, its affiliated brands' products, services and promotions. I can unsubscribe at any time.
                        </label>
                      </div>
                      <div className="consent-check mt-0">
                        <input
                          id="notifterms"
                          type="checkbox"
                          className="policyus-checkbox"
                          name="notificationsConsent"
                          checked={notificationsConsent}
                          onChange={this.handleCheckboxChange}
                        />
                        <label className="consent-check-label" htmlFor="notifterms">
                          Enable notifications to unlock exclusive updates and never miss a beat in our app.
                        </label>
                      </div>
                      <button type="submit" className="btn confirm-button w-100 mt-4 text-white">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
          <Modal
            open={showPopup}
            footer={false}
            headers={false}
            title={false}
            closable={false}
            centered
            style={{ maxWidth: '200px' }}
            bodyStyle={{ padding: '15px'}}
          >
            <div style={{ textAlign: 'center'}}>
              <p style={{fontWeight: '500', marginBottom: '15px'}}>{popupmsg}</p>
              <Button style={{ borderRadius: '25px', width:'75%', outline:'none', borderColor: '#000000', textDecorationColor: '#000000' }} onClick={() => this.setState({ showPopup: false })}>Ok</Button>
            </div>
          </Modal>
        <div className="spinner" style={{ display: this.state.isLoading ? 'block' : 'none' }}>
          <div style={style}>
            <ClipLoader
              size={50}
              color={"#FF0000"}
              loading={this.state.isLoading}
            />
          </div>
        </div>
      </>
    );
  }
}

export default NewSignUp;
